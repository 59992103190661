import App from "./App.vue"
import router from "./router"
import RayPlugin from "vue-ray"
import echo from "@/plugins/echo"
import { createPinia } from "pinia"
import FloatingVue from "floating-vue"
import Oruga from "@oruga-ui/oruga-next"
import Datepicker from "@vuepic/vue-datepicker"
import gfPrivileged from "./plugins/gf-privileged"
import { VueQueryPlugin } from "@tanstack/vue-query"
import { createApp, defineCustomElement } from "vue"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { vueQueryPluginOptions } from "@/plugins/vue-query"
import { library } from "@fortawesome/fontawesome-svg-core"
import Toast, { type PluginOptions } from "vue-toastification"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import AssignedStub from "@/components/stubs/AssignedStub.ce.vue"

import "@vuepic/vue-datepicker/dist/main.css"
import "vue-toastification/dist/index.css"
import "@/styles/tailwind.css"
import "@/styles/app.css"
import "floating-vue/dist/style.css"
import "animate.css"

const app = createApp(App)

/**
 * Font Awesome
 */
library.add(fas)
library.add(far)
library.add(fab)
app.component("FontAwesomeIcon", FontAwesomeIcon)

/**
 * Pinia
 */
app.use(createPinia())

/**
 * Vue Router
 */
app.use(router)

/**
 * Oruga
 */
app.use(Oruga, {
    iconComponent: "font-awesome-icon",
    iconPack: "fas",
})

/**
 * Echo
 */
app.use(echo)

/**
 * Vue Query
 */
app.use(VueQueryPlugin, vueQueryPluginOptions)

/**
 * Vue Toastification
 */
const options: PluginOptions = {
    shareAppContext: true,
    containerClassName: "gf-notification-container",
}
app.use(Toast, options)

/**
 * Floating Vue
 */
app.use(FloatingVue, {
    themes: {
        "editor-menu": {
            $extend: "dropdown",
        },
        "editor-submenu": {
            $extend: "dropdown",
        },
    },
})

/**
 * GF Privileged
 * To manage organization-user permissions
 */
app.use(gfPrivileged)

/**
 * Ray
 */
if (import.meta.env.VITE_RAY_ENABLED === "true")
    app.use(RayPlugin, {
        interceptErrors: true,
        host: "127.0.0.1",
    })

/**
 * Vue Datepicker
 * @see https://vue3datepicker.com/installation/
 */
app.component("DatePicker", Datepicker)

/**
 * Custom Elements
 */

// register
customElements.define("assigned-stub", defineCustomElement(AssignedStub))

/**
 * Mount
 */
app.mount("#app")
