<template>
    <div class="assigned-stub-tag">
        <slot></slot>
    </div>
</template>
<script setup lang="ts"></script>
<style>
.assigned-stub-tag {
    @apply pointer-events-none relative -top-0.5 inline-flex rounded-full border-blue-500 py-0.5 px-2 text-xs font-semibold text-blue-500;

    border: 1px solid #0d0d0d;
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
}
</style>
