import {
    MutationCache,
    QueryCache,
    type VueQueryPluginOptions,
} from "@tanstack/vue-query"
// import { onError } from "@/queries/errorHandling"

export const vueQueryPluginOptions: VueQueryPluginOptions = {
    enableDevtoolsV6Plugin: import.meta.env.VITE_VUE_QUERY_DEVTOOLS,
    queryClientConfig: {
        queryCache: new QueryCache({
            // Error handling moved to axios
            // onError: (e) => onError(e as any),
        }),
        mutationCache: new MutationCache({
            // onError: (e) => onError(e as any),
        }),
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                // TODO set retry only for 500 errors
                retry: false,
            },
        },
    },
}
